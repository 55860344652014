/* exo-2-100 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 100;
  src: local('Exo 2 Thin'), local('Exo2-Thin'),
       url('./exo-2-v7-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-100italic - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 100;
  src: local('Exo 2 Thin Italic'), local('Exo2-ThinItalic'),
       url('./exo-2-v7-latin-100italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-100italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-200 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 200;
  src: local('Exo 2 ExtraLight'), local('Exo2-ExtraLight'),
       url('./exo-2-v7-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-200italic - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 200;
  src: local('Exo 2 ExtraLight Italic'), local('Exo2-ExtraLightItalic'),
       url('./exo-2-v7-latin-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-300 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  src: local('Exo 2 Light'), local('Exo2-Light'),
       url('./exo-2-v7-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-300italic - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 300;
  src: local('Exo 2 Light Italic'), local('Exo2-LightItalic'),
       url('./exo-2-v7-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-regular - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  src: local('Exo 2 Regular'), local('Exo2-Regular'),
       url('./exo-2-v7-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-italic - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 400;
  src: local('Exo 2 Italic'), local('Exo2-Italic'),
       url('./exo-2-v7-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-500 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  src: local('Exo 2 Medium'), local('Exo2-Medium'),
       url('./exo-2-v7-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-500italic - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 500;
  src: local('Exo 2 Medium Italic'), local('Exo2-MediumItalic'),
       url('./exo-2-v7-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-600 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  src: local('Exo 2 SemiBold'), local('Exo2-SemiBold'),
       url('./exo-2-v7-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-600italic - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 600;
  src: local('Exo 2 SemiBold Italic'), local('Exo2-SemiBoldItalic'),
       url('./exo-2-v7-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-700 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  src: local('Exo 2 Bold'), local('Exo2-Bold'),
       url('./exo-2-v7-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-700italic - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 700;
  src: local('Exo 2 Bold Italic'), local('Exo2-BoldItalic'),
       url('./exo-2-v7-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-800 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 800;
  src: local('Exo 2 ExtraBold'), local('Exo2-ExtraBold'),
       url('./exo-2-v7-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-800italic - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 800;
  src: local('Exo 2 ExtraBold Italic'), local('Exo2-ExtraBoldItalic'),
       url('./exo-2-v7-latin-800italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-900 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 900;
  src: local('Exo 2 Black'), local('Exo2-Black'),
       url('./exo-2-v7-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-900italic - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 900;
  src: local('Exo 2 Black Italic'), local('Exo2-BlackItalic'),
       url('./exo-2-v7-latin-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./exo-2-v7-latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
